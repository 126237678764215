// @flow

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { api, fetchJSON, refreshheaderToken } from '../../helpers/api';

import { FORGET_PASSWORD, LOGIN_USER, LOGOUT_USER, REGISTER_USER } from './constants';

import {
    forgetPasswordFailed,
    forgetPasswordSuccess,
    loginUserFailed,
    loginUserSuccess,
    registerUserFailed,
    registerUserSuccess,
} from './actions';

/**
 * Sets the session
 * @param {*} user
 */
const setSession = (user) => {
    if (user) localStorage.setItem('user', JSON.stringify(user));
    else localStorage.removeItem('user');
};
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password , tries} }) {
    console.log(tries)
    let attempt = 0;
    try {
        const response = yield call(api.post, 'v1/admin/login', { email: username, password , lock : tries > 4});
        if (response.status === 200) {
            refreshheaderToken(response?.data?.token);

            localStorage.setItem('permission', JSON.stringify(response?.data?.role_ids));
            // if(response.data.role === 9 ||response.data.role === 14 || response.data.role === 15) {
            //     localStorage.setItem('permission', [2,3,4,5,6,7,8,9,10]);
            // }

            const response_userdata = yield call(api.get, 'v1/admin/loggeduser');
            let user = {};
            if (response_userdata.status === 200) {
                user = response_userdata?.data?.user;
            }

            // token, user, role, permission
            refreshheaderToken(response?.data?.token);
            localStorage.setItem('token', response?.data?.token);
            localStorage.setItem('active_user', JSON.stringify(user));
            console.log(response_userdata);
            setSession(response.data);

            yield put(loginUserSuccess(response.data));
        } else {
            yield put(loginUserFailed(response.message, attempt));
        }
    } catch (error) {
        console.log(error);
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                attempt = 0;
                break;
            case 401:
                message = error.response.data.message;
                attempt = tries + 1;
                break;
            default:
                message = error.response.data.message;
                attempt = 0;
        }
        yield put(loginUserFailed(message, attempt));
        setSession(null);
    }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
    try {
        setSession(null);

        yield call(() => {
            history.push('/account/login');
        });
    } catch (error) {}
}

/**
 * Register the user
 */
function* register({ payload: { fullname, email, password } }) {
    const options = {
        body: JSON.stringify({ fullname, email, password }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, '/users/register', options);
        yield put(registerUserSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(registerUserFailed(message));
    }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { username } }) {
    const options = {
        body: JSON.stringify({ username }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, '/users/password-reset', options);
        yield put(forgetPasswordSuccess(response.message));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(forgetPasswordFailed(message));
    }
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword() {
    yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

function* authSaga() {
    yield all([fork(watchLoginUser), fork(watchLogoutUser), fork(watchRegisterUser), fork(watchForgetPassword)]);
}

export default authSaga;
